<template>
	<div class="wrap">
		<div class="">
			<div class="goods-title">
				<h4>{{ $lang(goods,'title') }}</h4>
			</div>
			<div class="goods-params">
				<div>
					<div class="goods-imgs">

						<img :src=" $req.getFileUrl( goods.img)  " border="0" width="300" alt="" />
					</div>
					<div style="display: flex;align-items: center;width: 300px;position: relative;margin-top: 10px;">
						<el-image v-for="(item,index) in goods.banner" :key="index" v-if="goods.banner.length"
							style="width: 50px; height: 50px;margin-right: 5px;" :src=" $req.getFileUrl( item) "
							@click="checkImg(item,fileServer)">
						</el-image>
					</div>
				</div>
				<div class="goods-content">
					<el-form label-width="200px">
						<!-- 价格 -->
						<el-form-item :label="$t('goodsDetail.goodsPrice')">
							<span style="color: #E70000;">￥{{ goods.uniMoneyBatch }}</span>
						</el-form-item>
						<!-- 样品价格 -->
						<el-form-item :label="$t('goodsDetail.smaplePrice')">
							<span>￥{{ goods. uniMoneySample}}</span>
						</el-form-item>
						<!-- 销量 -->
						<el-form-item :label="$t('goodsDetail.sale')">
							<span>{{ goods.sales}}</span>
						</el-form-item>
						<el-form-item :label="$t('mall.typeNumber')">
							<span>{{ goods.model}}</span>
						</el-form-item>


						<!-- 起订量 -->
						<el-form-item :label="$t('mall.MinimumOrderQuantity')">
							<span>{{ goods.minimumQuantity}}</span>
						</el-form-item>
						<!-- 起订量 -->
						<!-- 		<el-form-item :label="$t('goodsDetail.standardNember')">
							<span>{{ goods.minimumQuantity}}</span>
						</el-form-item>
 -->
						<!-- 数量 -->
						<el-form-item :label="$t('goodsDetail.num')">
							<el-input-number v-model="gooodnum" :min="min" step-strictly :step="addNum">
							</el-input-number>
						</el-form-item>




						<!-- 付款类型 -->
						<el-form-item :label="$t('goodsDetail.supplyModes')">
							<el-select v-model="bookStatus" size="small">
								<el-option value="bookSign" :label="$t('goodsDetail.smaplePriceValue[1]')  ">
								</el-option>
								<el-option value="bookAll" :label="$t('goodsDetail.smaplePriceValue[0]')  "></el-option>
							</el-select>
						</el-form-item>

						<!-- 包装大小 -->
						<el-form-item :label="$t('goodsDetail.packNum')">
							<el-select v-model="size" size="small">


								<el-option value="sizeSmall" :label="$t('goodsDetail.boxSizeValue[0]')  "></el-option>
								<el-option value="sizeBig" :label="$t('goodsDetail.boxSizeValue[1]')  "></el-option>
							</el-select>
						</el-form-item>


						<el-form-item :label="$t('newadd.t.性能检测')">
							<el-button plain @click="checkNature">{{ $t("newadd.t.开始检测") }}
							</el-button>
						</el-form-item>


						<!-- 按钮 -->
						<el-form-item>
							<el-button type="primary" @click="orderNow" size="medium">{{ $t("operation.orderNow") }}
							</el-button>
						</el-form-item>
					</el-form>

				</div>
			</div>
			<div class="goods-params-list">
				<div>
					<!--<span style="color: #ccc;">{{ $t("goodsDetail.goodsModel") }}</span>-->
					<span>
						<i class="el-icon-truck" style="color:red;"></i>
						{{ $t("goodsDetail.FreightInsurance") }}
					</span>
				</div>
				<!-- <div>
					<span style="color: #ccc;">{{ $t("goodsDetail.TransactionSupport") }}</span>
					<span>
						<li class="el-icon-umbrella" style="color:red;"></li>{{ $t("goodsDetail.InsurancePayment") }}
					</span>
				</div> -->
				<div>
					<!--<span style="color: #ccc;">{{ $t("goodsDetail.Payment") }}</span>-->
					<span>
						<li class="el-icon-umbrella" style="color:red;"></li>
						{{ $t("goodsDetail.PaymentMethod") }}
					</span>
				</div>
			</div>
			<div class="goods-info">
				<div class="goods-info-title">
					<h4>{{ $t("goodsDetail.goodsInfo") }}</h4>
				</div>
				<el-row>
					<el-col v-for="item in goods.attrList" :span="12" style="text-align: center;padding: 20px;"
						:key="item.id">
						{{$lang(item,'name')}}: {{$lang(item,'value')}}

					</el-col>
				</el-row>
			</div>

			<hr>

			<div class="goods-detail" style="padding-top: 20px;">


				<p v-html="$lang(goods,'content')"></p>

			</div>



			<el-dialog :visible.sync="dialogTableVisible">
				<selectStatus @getInfo="getInfo"></selectStatus>
			</el-dialog>

			<el-dialog :visible.sync="dialogRuselt">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column :label="$t('custom.page2.换热量') " width="150">
						<template slot-scope="scope">
							<span>{{ scope.row.dQ }} {{userUnit.heatLoad}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('newadd.t.空气测压降') " width="150">
						<template slot-scope="scope">
							<span>{{ scope.row.dDP_a }} {{userUnit.airPressureDrop}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('custom.page2.冷媒降压') " width="150">
						<template slot-scope="scope">
						 <span>{{ scope.row.dDP_r }} {{userUnit.refPressureDrop}}</span>
						</template>
					</el-table-column>

					<el-table-column :label="$t('custom.page0.风速') " width="150">
						<template slot-scope="scope">
							<span>{{ scope.row.dVel_a }} {{userUnit.velocity}}</span>
						</template>

					</el-table-column>
					<el-table-column :label="$t('custom.page0.风量') " width="150">
						<template slot-scope="scope">
							<span>{{ scope.row.dVF_a }} {{userUnit.volumeFlow}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('newadd.t.冷媒质量流量') " width="150">
						<template slot-scope="scope">
							<span>{{ scope.row.dMF_r }} {{userUnit.massFlow}}</span>
						</template>
					</el-table-column>
				</el-table>
			</el-dialog>


		</div>
	</div>
</template>

<script>
	import {
		Check
	} from "@/utils/ConditionSwitch";
	import {
		getGoodsNormalDetail,
		checkout
	} from "@/api/goods";
	import selectStatus from "@/views/Custom/pages/page0";
	import {
		mapState,
		mapMutations
	} from "vuex";

	export default {
		name: 'goodsDetail',
		components: {
			selectStatus
		},
		computed: {
			...mapState(["fileServer"]),


			watchbook() {
				const bookStatus = this.bookStatus;
				const minimumQuantity = this.goods.minimumQuantity;
				return {
					bookStatus,
					minimumQuantity,
				}
			},


		},
		watch: {
			// 监听订购方式
			watchbook: {
				immediate: true,
				handler(v) {
					if (v.bookStatus == 'bookSign') {
						this.min = 1;
						this.addNum = 1;

					} else {
						this.min = v.minimumQuantity;
						this.gooodnum = v.minimumQuantity;

					}
				}
			},
			// 监听大小包装
			size(v) {
				if (v == 'sizeBig') {
					this.addNum = this.goods.productNumLarge;
					this.gooodnum = this.goods.productNumLarge;
				} else if (v == 'sizeSmall') {
					this.addNum = this.goods.productNumSmall;
					this.gooodnum = this.goods.productNumSmall;
				} else {
					this.addNum = 1;
				}
			}
		},

		data() {

			return {
				dialogTableVisible: false,
				dialogRuselt: false,
				size: 'sizeSmall',
				bookStatus: 'bookSign',
				payType: 'payAll',
				addNum: '', //批量订购下数量增加
				value: '',
				gooodnum: "",
				goods: {

				},
				currentobj: {},
				id: '',
				min: '',
				max: '',
				tableData: [],
				userUnit: {

				}

			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getUserUnit()
			this.getGoodsDetail(this.id)

		},
		methods: {

			// 
			checkNature() {
				const {
					detailType
				} = this.$route.query;
				if (detailType == "501" || detailType == "401") {
					this.$notify.info({
						title: this.$t('inviteCode.mesAlertTitle'),
						message: this.$t('newadd.t.功能开发中'),
						duration: 2000
					});
					return
				}
				this.dialogTableVisible = true;
			},



			async getUserUnit() {
				this.userUnit = await this.$store.dispatch("GetUserUnitOrCache");
			},
			// 工况选行
			getInfo(e) {
				e.model = this.goods.model
				Check(e).then(res => {
					const {
						detailType
					} = this.$route.query;
					checkout(res, detailType).then(res => {
						this.tableData = [res]
						this.dialogTableVisible = false;
						this.dialogRuselt = true;

					}).catch(err => {


					})
				})
			},

			getGoodsDetail(id) {
				getGoodsNormalDetail(id).then(res => {
					res.banner = res.banner.split(",");
					this.goods = res;
				}).catch(err => {})

			},
			orderNow() {
				var price = '';
				switch (this.bookStatus) {
					case 'bookSign':
						price = this.goods.uniMoneySample
						break;
					case 'bookAll':
						price = this.goods.uniMoneyBatch
						break;
				}

				var obj = this.goods;
				obj.gooodnum = this.gooodnum;
				obj.size = this.size == 'sizeBig' ? 'L' : this.size == 'sizeSmall' ? 'S' : '';
				obj.price = this.bookStatus == 'bookAll' ? this.goods.uniMoneyBatch : this.bookStatus == 'bookSign' ? this
					.goods.uniMoneySample : '';

				this.$router.push({
					name: "goodsNormallPay",
					params: {
						info: [obj]
					}
				})
				this.$store.commit("SET_PARAMS", [obj])

			},
			checkImg(item, fileServer) {
				this.goods.img = this.$req.getFileUrl(item)

			},

		}
	}
</script>

<style scoped>
	@import url("./DetailCommon.css");
</style>
